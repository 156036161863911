<template>
  <v-card
    elevation="0"
    color="none"
    :width="width"
    :height="height"
  >
    <v-img
      v-if="existingFile && fileType === 'image'"
      :src="existingFile"
      height="100%"
      style="border-radius: 8px;"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0 leaf"
          align="center"
          justify="center"
        />
      </template>
    </v-img>
    <div
      v-else-if="existingFile"
      class="d-flex flex-column align-center justify-center pa-3 text-center"
      style="height: 100%; border: 1px dashed var(--v-metal-base);"
    >
      <div class="headline">
        PDF Uploaded
      </div>
      {{ existingFile }}
    </div>
    <div
      v-else
      class="d-flex align-center justify-center"
      style="height: 100%; border: 1px dashed var(--v-metal-base);"
    >
      {{ emptyMessage }}
    </div>
    <v-dialog
      v-model="showImageForm"
      width="500"
      eager
    >
      <template #activator="{ on: onDialog }">
        <v-tooltip left>
          <template #activator="{ on: onTooltip }">
            <v-btn
              absolute
              fab
              bottom
              depressed
              right
              :color="existingFile ? 'white' : 'shamrock'"
              :dark="!existingFile"
              style="transform: translateY(-35px);"
              small
              v-on="{ ...onTooltip, ...onDialog }"
            >
              <v-icon small>
                {{ icon }}
              </v-icon>
            </v-btn>
          </template>
          {{ existingFile ? `Replace ${fileType}` : `Add ${fileType}` }}
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title>
          Upload new {{ fileType }}
        </v-card-title>
        <v-card-text
          v-if="!disabledText"
          class="pb-3"
        >
          <form
            v-show="!uploading"
            ref="dropzone"
            class="dropzone"
          />
          <v-progress-linear
            v-if="uploading"
            class="my-2"
            color="shamrock"
            indeterminate
          />
        </v-card-text>
        <v-card-text v-else>
          <br>
          {{ disabledText }}
        </v-card-text>
        <v-card-actions class="px-7 pb-5">
          <slot name="footer" />
          <v-spacer />
          <v-btn
            v-if="disabledText"
            color="shamrock white--text"
            @click="showImageForm = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Dropzone from 'dropzone'

export default {
  name: 'FileUpload',
  props: {
    existingFile: { type: [String, null], default: null },
    emptyMessage: { type: String, default: 'No image yet' },
    fileType: { validator: (e) => ['image', 'pdf'].includes(e), required: true },
    url: { type: String, required: true },
    method: { type: String, default: 'put' },
    width: { type: String, default: undefined },
    height: { type: String, default: undefined },
    disabledText: { type: String, default: null }
  },
  data () {
    return {
      showImageForm: false,
      dropzone: null,
      uploading: false
    }
  },
  computed: {
    icon () {
      if (this.fileType === 'image') {
        return this.existingFile ? 'mdi-image-multiple' : 'mdi-camera-plus'
      } else {
        return 'mdi-file-upload'
      }
    },
    acceptedFiles () {
      const lookup = {
        image: 'image/*',
        pdf: 'application/pdf'
      }
      return lookup[this.fileType]
    }
  },
  mounted () {
    if (!this.dropzone) {
      this.dropzone = new Dropzone(this.$refs.dropzone, {
        maxFilesize: 5,
        maxFiles: 1,
        createImageThumbnails: false,
        url: this.url,
        method: this.method,
        dictDefaultMessage: 'Drop files here or click to choose from your computer',
        headers: {
          'X-Requested-With': null,
          authorization: `Bearer ${localStorage.getItem('cpd-tk')}`
        },
        acceptedFiles: this.acceptedFiles
      })
      this.dropzone.on('addedfile', (file) => {
        this.uploading = true
      })
      this.dropzone.on('success', (file, image) => {
        this.showImageForm = false
        this.dropzone.removeFile(file)
        this.uploading = false
        this.$emit('uploaded', image)
      })
      this.dropzone.on('error', (file) => {
        this.dropzone.removeAllFiles()
        this.uploading = false
        this.$store.dispatch('showSnackbar', {
          color: 'error',
          text: this.acceptedFiles.includes(file.type) ? 'Error uploading image. Please wait a few moments and try again.' : `That file type isn't valid. Please upload a ${this.fileType}`
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .dropzone {
    height: 200px;
    border: 2px dashed var(--v-shamrock-lighten1);
    color: var(--v-shamrock-lighten1);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .dz-message button {
      font-size: 17px;
    }
  }
</style>
